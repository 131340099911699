<script setup>
import { useRouter } from 'vue-router';
import { onMounted, ref } from 'vue';


const msg = ref('Connect wallet and join us!');
const router = useRouter();

const getProvider = () => {
  if ('phantom' in window) {
    const provider = window.phantom?.solana;
    if (provider?.isPhantom) {
      return provider;
    }
  }
  msg.value = 'Phantom Wallet is not available. Install Phantom using the button below.';
  return null;
};

onMounted(async () => {
  const provider = await getProvider();
  if (!provider) {
    return;
  }
  provider.on('accountChanged', (publicKey) => {
      if (publicKey) {
          // Refresh the page to reflect the new account unless we are in dashboard
          if (window.location.pathname != '/dashboard') {
            window.location.reload();
          }
      } 
  });
  try {
    const resp = await provider.connect();
    if (resp.publicKey.toString()) {
      await router.push({name: 'LoginAndRegister'});
    }
  } catch (err) {

    console.error(err);
  }
});

const openPhantomSite = async () => {
  window.open("https://phantom.app/", "_blank");
};

</script>

<template>
  <div id="app" class="flex flex-col h-full">
    <div class="flex-grow flex justify-center items-center">
      <div class="container p-3">
        <div class="greetings">
          <h1 class="text-black text-md mb-20">{{ msg }}</h1>
          <button @click="openPhantomSite" target="_blank" style="text-shadow: 0 0 black;"
            class="primary-button">INSTALL</button>
        </div>
      </div>
    </div>
  </div>
</template>
