import axios from 'axios';

class SolanaRPC {
    constructor(endpoint, commitment = 'finalized') {
        this.endpoint = endpoint;
        this.commitment = commitment;
    }

    async call(method, params = []) {
        const response = await axios.post(this.endpoint, {
            jsonrpc: '2.0',
            id: 1,
            method,
            params
        });

        if (response.data.error) {
            throw new Error(response.data.error.message);
        }

        return response.data.result;
    }
    async getBalance(address) {
        const result = await this.call('getBalance', [address, {commitment: this.commitment}]);
        return result.value / 1e9; // Convert lamports to SOL
    }

    async getTokenAccountsByOwner(ownerAddress, mintAddress) {
        const result = await this.call('getTokenAccountsByOwner', [
            ownerAddress,
            {mint: mintAddress},
            {encoding: 'jsonParsed', commitment: this.commitment}
        ]);
        return result.value;
    }

    async getTokenBalance(tokenAccount) {
        const result = await this.call('getTokenAccountBalance', [tokenAccount, {commitment: this.commitment}]);
        return result.value;
    }

    async getBeracoinBalance(ownerAddress, beracoinMint) {
        try {
            const accounts = await this.getTokenAccountsByOwner(ownerAddress, beracoinMint);
            if (accounts.length === 0) {
                return 0;
            }

            let totalBalance = 0;
            for (const account of accounts) {
                const balance = await this.getTokenBalance(account.pubkey);
                totalBalance += parseFloat(balance.uiAmount);
            }

            return totalBalance;
        } catch (error) {
            console.error('Error al obtener el balance de Beracoin:', error);
            throw error;
        }
    }
}

export default SolanaRPC;