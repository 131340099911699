import { createRouter, createWebHistory } from 'vue-router';
import LoginAndRegister from '../views/LoginAndRegister.vue';
import Home from '../views/HomeView.vue';
import Dashboard from '../views/DashboardView.vue';
import authMiddleware from '../middleware/authMiddleware';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'LoginAndRegister',
    component: LoginAndRegister,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    // You can add props: true if you want to pass query params as props
    // props: route => ({ query: route.query }),
  },
];

const router = createRouter({
  history: createWebHistory(), 
  routes,
});

router.beforeEach(authMiddleware);

export default router;
